<template>
  <transition>
    <router-view></router-view>
  </transition>
</template>

<style lang="scss">
body, html {
  background-color: #002039;
}
</style>
