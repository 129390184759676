<template>
  <div id="home" class="overflow-x-clip h-full absolute w-full">    
    <header class="w-full h-[40%] sm:h-3/5">
      <section class="h-full topheader flex justify-center">
        <nav class="duration-300 text-white fixed w-full bg-[#17558580] px-4 z-50 font-[roboto] backdrop-blur-md p-2 sm:text-xs text-[10px] flex justify-center h-12 max-h-96 items-center normalnav">
          <a href="" class="gap-2 duration-200 mr-auto ml-3 italic text-white h-full flex items-center justify-center text-base font-bold tasheader">
            <img class="w-4" src="../assets/football.png" alt="">
            TAS
          </a>
          
          <div class="sm:flex h-full ml-auto uppercase text-[12px] hidden md:gap-0">
            <a v-for="(navlinks, index) in navlinks" :key="index" :href="navlinks.url" class="h-full duration-200 flex items-center justify-center px-2 lg:px-4">{{ navlinks.name }}</a>
          </div>
          
          <a href="" class="sm:flex hidden uppercase duration-200 bg-blue-700 ml-4 hover:bg-blue-600 text-white py-1.5 rounded-[2px] mr-4 -skew-x-12 px-4 lg:px-6">Contact</a>
          
          <input id="toggle" type="checkbox" class="hidden">
          
          <label @click="expandnav" for="toggle" class="hamburger duration-300 absolute top-1.5 right-4 cursor-pointer sm:hidden">
            <div v-for="(classname, index) in ['top-bun', 'meat', 'bottom-bun']" :key="index" :class="classname"></div>
          </label>
          
          <div class="hiddennav duration-500 -translate-y-10 hidden">
            <a v-for="(navlinks, index) in navlinks" :key="index" :href="navlinks.url"  class="hover:bg-[#11111190] h-full text-lg duration-200 mt-2 flex items-center justify-center px-2 lg:px-6">{{ navlinks.name }}</a>
          </div>
        </nav>
        
        <section class="inline-flex flex-col items-start sm:pl-20 pl-8 w-full pt-[70px] sm:pt-32 backdrop-blur-sm">
          <h1 class="text-white md:text-6xl text-2xl leading-tight font-bold uppercase font-[Roboto] italic">Torquay <br>
            <div class="flex items-center justify-center">
              academy Sp<img class="size-7 md:size-16 md:-mr-2 ml-1" src="https://img.icons8.com/?size=100&id=xsj5Zlx99Bjv&format=png&color=000000">rt
              F<router-link to="/service" class="cursor-auto">C</router-link>
            </div>
          </h1>
          
          <p class="opacity-90 font-light sm:w-1/2 w-2/3 mt-2 text-xs sm:text-base lg:text-xl text-white italic">Welcome to the official Torquay Academy Football site. Check out our football team!</p>
          <hr class="w-1/2 mt-4 border-2 rounded-full">
          
          <!-- <div class="flex flex-col text-white mt-8">
            <h2 class="scale-y-125 font-bold uppercase text-xl">next match</h2>
            
            <div class="flex gap-2 font-semibold mt-4 text-lg">
              <h4>{{ match1value }}</h4>
              <span>VS</span>
              <h4>{{ match2value }}</h4>
            </div>
            
            <div>
              <p class="mt-1">{{ datevalue }} - {{ locationvalue }}</p>
            </div>
          </div> -->
        </section>
      </section>
    </header>
    
    <main class="bg-[#002039]">
      <section class="sponsor_swiper flex flex-col items-center justify-center border-t-4 gap-2">
        
        <div class="flex bg-[#175585] py-4 md:px-12 px-6 items-center justify-center w-full">
          <h2 id="latestnewstitle" class="newstitle mr-auto md:pl-6 text-white newstitle md:text-2xl font-bold scale-y-125 tracking-tight italic uppercase">Latest News</h2>
          <button class=" text-white md:text-base text-sm" id="viewallbutton" @click="showallnews">View all</button>
        </div>
        
        <section class="md:py-6 py-2 md:pb-12 flex flex-wrap max-w-[1000px] mx-auto items-center justify-center w-full text-white gap-3 md:my-4 newsboxcontainer">
        </section>
      </section>
      
      <section class="cards_sponsors082f4d bg-white md:bg-[#002039]">
        <section class="md:pb-20 pb-5">
          <h2 class="text-white border-t-2 md:mb-14 mb-0 newstitle text-left pl-6 md:pl-12 bg-[#175585] py-2 md:text-2xl font-bold scale-y-125 tracking-tight italic uppercase">Current Teams</h2>
          
          <div class="md:bg-white md:mt-[70px] rounded-3xl md:w-2/3 max-w-[530px] mx-auto">
            <Swiper
            :grabCursor="true" 
            :centeredSlides="true" 
            :initialSlide="0" 
            :slidesPerView="1"
            :direction="'vertical'"
            
            :pagination="{ el: '.swiper-pagination', clickable: true }"
            :effect="'fade'"
            :fadeEffect="{ crossFade: true }"
            :modules="modules"
            pagination="true"
            class="teams-swiper h-[430px] md:h-60 font-[Fira Sans] overflow-visible"
            @slideChange="onSlideChange"
            @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
            >
            
            <swiper-slide v-for="(team, index) in teams" :key="index" class="teamsswiper">
              <div :id="'team' + (index + 1) + 'image'"></div>
              <div class="teamcontent">
                <p>Manager: {{ team.manager }}</p>
                <h4>{{ team.teamname }}</h4>
                <p>{{ team.description }}</p>
              </div>
            </swiper-slide>
            
            <div class="swiper-pagination mr-2 mt-40 md:mt-0"></div>
          </Swiper>
        </div>
      </section>
      
      <section class="md:pb-4 bg-[#002039]">
        <h2 class="text-white border-t-2 sm:mb-14 mb-7 text-left pl-6 md:pl-12 bg-[#175585] py-2 md:text-2xl font-bold scale-y-125 tracking-tight italic uppercase">Past Teams</h2>
        
        <section class="pastteams-container md:px-10 pb-10 mt-0 pt-0">
          <Swiper
          :modules="modules"
          :slidesPerView="3"
          :spaceBetween="10"
          :initialSlide="6"
          :centeredSlides="true"
          >
          
          <swiper-slide v-for="n in 10" class="swiperslide"></swiper-slide>
        </Swiper>
      </section>
    </section>
    
    <section class="pb-16 sponsorsback bg-[#002039]">
      <h2 class="text-white border-t-2 newstitle text-left pl-6 md:pl-12 bg-[#175585] py-2 md:text-2xl font-bold scale-y-125 tracking-tight italic uppercase">Sponsors</h2>
      
      <Swiper
      class="SponsorsSwiper w-72 mt-10"
      :effect="'cards'"
      :grabCursor="true"
      :modules="modules">
      
      <swiper-slide v-for="(sponsorsSwiperSlides, index) in ['bg-blue-200', 'bg-purple-200', 'bg-red-200', 'bg-green-200', 'bg-yellow-200']" :key="index" :class="sponsorsSwiperSlides" class="swiperslide backdrop-blur-md">
        <div :id="'swiperslide' + (index + 1)" alt=""></div>
        
        <h2 class="font-semibold mt-3 uppercase">John Doe</h2>
        <p class="text-xs sm:text-xs mt-3">John is one of our biggest sponsors and has a child on team 1. Placeholder text goes here so this needs changing.</p>
        <p class="text-xs sm:text-xs mt-3">John is one of our biggest sponsors and has a child on team 1. Placeholder text goes here so this needs changing.</p>
      </swiper-slide>
    </Swiper>
  </section>
</section>

<!-- <section class="flex flex-col pb-4 sm:pb-16 items-center justify-center md:mt-10 mt-6">
  <h2 class="text-white newstitle text-left pl-12 bg-black text-2xl font-bold scale-y-125 tracking-tight italic uppercase">committee</h2>
  
  <section class="flex sm:p-10 scale-75 sm:w-screen sm:scale-100 committemembers relative overflow-hidden">
    <div class="scroll-container flex gap-6">
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John #</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
      <div class="commmembers rounded-3xl font-bold text-white flex duration-200 flex-col justify-end w-60 h-80 bg-black">
        <span class="backdrop-blur-2xl rounded-b-3xl px-6 py-3">
          <h2 class="uppercase">John Doe</h2>
          <p class="font-light">Title of person</p>
        </span>
      </div>
    </div>
  </section>
</section> -->

<section class="pb-4 mt-10 sm:pb-16">
  <section class="w-11/12 max-w-[1000px] mx-auto flex flex-col gap-2 text-white justify-center">
    <h2 class="text-2xl ml-4 font-bold scale-y-125 tracking-tight italic uppercase">Policies</h2>
    <p class="ml-4 text-orange-300 mt-0">Applicable on: <span id="date"></span></p>
    
    <hr class="mt-4">
    <div class="w-full px-2 sm:p-2 rounded flex items-center policyboxes text-white flex-col">
      <div class="w-full sm:px-4 flex items-center">
        <h2 class="sm:text-xl font-semibold tracking-tight italic uppercase">Spectators and Parents/Carers</h2>
        
        <button @click="ExpandPolicy($event.currentTarget)" class="plusminus ml-auto scale-75 sm:scale-100"></button>
      </div>
      
      <div class="text-[10px] h-0 duration-300 overflow-y-hidden md:text-xs lg:text-lg sm:gap-10 gap-4 font-light md:flex-row flex-col px-4 md:px-10 mr-auto policytext">
        <ul class="list-disc">
          <li>Have fun; it's what we're all here for!</li>
          <li>Celebrate effort and good play from both sides</li>
          <li>Always respect the Referee and coaches and encourage players to do the same</li>
          <li>Stay behind the touchline and within the Designated Spectators' Area (where provided)</li>
          <li>When players make mistakes, offer them encouragement to try again next time</li>
          <li>Never engage in, or tolerate offensive, insulting or abusive language or behaviour</li>
        </ul>
        
        <div>
          <p class="font-semibold -ml-4 mt-4 mb-2">I understand that if I do not follow the Code, I may be:</p>
          <ul class="list-disc">
            <li>Issued with a verbal warning or asked to leave</li>
            <li>Required to meet with the club committee, league or CFA Welfare Officer</li>
            <li>Requested not to attend future games, be suspended or have my membership removed</li>
            <li>Required to leave the club along with any dependants and/or issued a fine</li>
          </ul>
        </div>
      </div>
    </div>
    
    <hr>
    <div class="w-full px-2 sm:p-2 rounded flex items-center policyboxes text-white flex-col">
      <div class="w-full sm:px-4 flex items-center">
        <h2 class="sm:text-xl font-semibold tracking-tight italic uppercase">Young Players</h2>
        
        <button @click="ExpandPolicy($event.currentTarget)" class="plusminus ml-auto scale-75 sm:scale-100"></button>
      </div>
      
      <div class="text-[10px] h-0 duration-300 overflow-y-hidden md:text-xs lg:text-lg sm:gap-10 gap-4 font-light md:flex-row flex-col px-4 md:px-10 mr-auto policytext">
        <ul class="list-disc">
          <li>Always play my best for the benefit of the team</li>
          <li>Play fairly and be friendly</li>
          <li>Play by the rules and respect the Referee</li>
          <li>Shake hands with the other team - win or lose</li>
          <li>Listen carefully to what my coach tells me</li>
          <li>Understand that a coach has to do what's best for the team</li>
          <li>Talk to someone I trust or the club welfare officer if I'm unhappy about anything at my club</li>
          <li>Encourage my team mates</li>
          <li>Respect the facilities home & away</li>
        </ul>
        
        <div>
          <p class="font-semibold -ml-4 mt-4 mb-2">I understand that if I do not follow the Code, I may be:</p>
          <ul class="list-disc">
            <li>be asked to apologise to whoever I've upset</li>
            <li>Receive a formal warning</li>
            <li>be dropped, substituted or suspended from training</li>
          </ul>
        </div>
      </div>
    </div>
    <hr>
  </section>
</section>

<section class="py-10">
  <section class="contactform w-[90%] lg:w-2/3 text-white max-w-[700px] mx-auto rounded-md flex items-center justify-center">
    <div class="flex rounded-md backdrop-blur-md bg-black/50 size-full py-6 sm:py-10 items-center">
      <div class="hidden sm:flex sm:w-2/5 w-1/3 h-full items-center justify-center">
        <img class="md:w-1/2 w-2/3 opacity-50" src="../assets/phone1.png" alt="">
      </div>
      
      <div class="w-full sm:w-3/5 h-full flex flex-col items-center sm:items-start justify-center">
        <h2 class="scale-y-125 uppercase italic text-2xl font-bold mr-auto pl-5 sm:pl-0">get in touch</h2>
        <p class="opacity-70 mt-2 text-sm sm:text-base pl-5 sm:pl-0">Fill out the form below and we will get back to you as soon as possible</p>
        
        <form action="" class="w-[90%] mt-6" method="POST">
          <input type="text" class="w-full py-1 pl-2 rounded-md outline-none bg-white/10 text-white" placeholder="Name">
          <input type="email" class="w-full py-1 pl-2 mt-2 rounded-md outline-none bg-white/10 text-white" placeholder="Email">
          <textarea class="w-full resize-none py-1 pl-2 mt-2 h-20 rounded-md outline-none bg-white/10 text-white" placeholder="Message"></textarea>
          <input type="submit" class="hover:bg-white/20 duration-300 p-1 px-10 mt-2 rounded-md bg-white/10 text-white cursor-pointer" value="Submit">
        </form>
      </div>
    </div>
  </section>
</section>
</main>

<footer class="bg-[#175585] text-white text-center p-8">
  <section class="flex gap-8 items-center justify-center font-thin text-xs md:text-sm max-w-2xl mx-auto">
    <button class="underline">Phone: <a href="tel:07597387006">07597387006</a></button>
    <p>Copyright <span>{{ copyrightyear }}</span> © TasFC</p>
    <a class="underline" href="mailto:tasfc50@gmail.com">Email: tasfc50@gmail.com</a>
  </section>
  <!-- <h2 class="scale-y-110">Torquay Academy FC © 2024 - {{ footerdate }} - Created with ♱ by <a href="https://jacksweb.co.uk" target="_blank" class="text-green-600 underline">JacksWeb</a></h2> -->
</footer>
</div>
</template>

<script setup>
import { ref, onMounted  } from 'vue';

import { supabase } from '../clients/supabase';

// Swiper js
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import { EffectCards, EffectFade, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';

// Animations
import Lenis from 'lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const modules = [EffectCards, EffectFade, Pagination, Autoplay, EffectCoverflow];
const lenis = new Lenis();
const match1value = ref("");
const match2value = ref("");
const datevalue = ref("");
const locationvalue = ref("");
const news_headline = ref("");
const footerdate = getYear();
const showhiddenteams = ref(false);
const ExpandSpecAndParents = ref(false);
const ExpandYoungPlayers = ref(false);
const copyrightyear = getYear();

const teams = [
{ manager: "Barry Munro", teamname: "U9 Predators", description: "This is our U9 Predators. Managed by Barry Munro with assisted coaching from Wayne Leaver and James Munro." },
{ manager: "Dan Butterworth", teamname: "U12 Colts", description: "This is our U12 Colts. Managed by Dan Butterworth with assisted coaching from Steven Courtier, Adam Robertson, and Paul Wood." },
{ manager: "Des Pitman", teamname: "U14 Predators", description: "This is our U14 Predators. Managed by Des Pitman with assisted coaching from Scott Somerset and Chris Watts." },
{ manager: "Luke Asplen", teamname: "U14", description: "This is our U14 team. Managed by Luke Asplen with assisted coaching from Derek Fields and Neville Wrathall." },
{ manager: "Jay Clark", teamname: "U10 Colts", description: "This is our U10 Colts. Managed by Jay Clark with assisted coaching from Alan Gowland and Pete Colman." },
{ manager: "Karl Williams", teamname: "U10 Trojans", description: "This is our U10 Trojans. Managed by Karl Williams with assisted coaching from David Grove and Pete Coleman." },
{ manager: "Lee Davis", teamname: "U11 Blacks", description: "THis is our U11 Blacks. Managed by Lee Davis with assisted coaching from Adam Newman." }
];

const navlinks = [
{ name: "Home", url: "" },
{ name: "Teams", url: "" },
{ name: "Sponsors", url: "" },
{ name: "Committee", url: "" },
{ name: "Policies", url: "" },
{ name: "Contact", url: "" }
]

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}
requestAnimationFrame(raf);

function nextslide() {
  const swiper = document.querySelector('.teams-swiper').swiper;
  swiper.slideNext();
}
function prevslide() {
  const swiper = document.querySelector('.teams-swiper').swiper;
  swiper.slidePrev();
}

function getYear() {
  return new Date().getFullYear();
}

function getDate() {
  const collecteddate = document.getElementById('date');
  
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  
  collecteddate.textContent = `${day}/${month}/${year}`;
}

function HideExpandedNews() {
  const expandednews = document.querySelector('.expandednews');
  
  expandednews.style.opacity = "0";
}

async function get_news_db() {
  const newsboxcontainer = document.querySelector('.newsboxcontainer');
  
  let { data: news_table, error } = await supabase
  .from('news_table')
  .select('*');
  
  if (error) {
    console.error('Error fetching data:', error.message);
    return;
  }
  
  news_table.forEach((news, index) => {
    const { news_date, news_heading, news_para } = news;
    
    if (!news_heading || news_heading.trim() === "null") {
      return;
    }
    
    const newnews = document.createElement('div');
    newnews.classList.add('news_boxes');
    newnews.innerHTML = `
      <div class="w-full h-20 sm:h-40 object-cover sm:rounded newsimages newimage${index}" data-index="${index}"></div>
      <p class="text-sm mt-2 mb-1 opacity-80">${news_date}</p>
      <h2 class="mb-3 -mt-1 font-semibold newsheading">${news_heading}</h2>
      <p class="newsdesc font-light mt-4 hidden">${news_para}</p>
    `;
    
    newnews.querySelector('.newsimages').addEventListener('click', expandnews);
    newsboxcontainer.appendChild(newnews);
  });
}


function expandnews(event) {
  const newsElement = event.currentTarget;
  const newsBox = newsElement.closest('.news_boxes');
  const newsDesc = newsBox.querySelector('.newsdesc');
  
  newsDesc.style.display === "block" ? newsDesc.style.display = "none" : newsDesc.style.display = "block";
}

async function get_DB_Values() {
  get_news_db()
  
  const { data, error } = await supabase
  .from('tas_database')
  .select('*')
  .eq('id', 22);
  
  if (error) {
    console.error('Error fetching data:', error);
    return;
  }
  
  const rowData = data[0];
  
  if (!rowData) {
    console.error('No data found for id 22');
    return;
  }
  
  match1value.value = rowData.match_1 || '';
  match2value.value = rowData.match_2 || '';
  news_headline.value = rowData.news || '';
  locationvalue.value = rowData.location || '';
  datevalue.value = rowData.date || '';
}

function expandnav() {
  const [nav, hiddennav] = [document.querySelector(".normalnav"), document.querySelector(".hiddennav")];
  const isExpanded = nav.style.height == "40%";
  
  nav.style.height = isExpanded ? "48px" : "40%";
  hiddennav.style.display = isExpanded ? "none" : "block";
  hiddennav.style.transform = isExpanded ? "translateY(-40px)" : "translateY(0)";
  
  if (!isExpanded) {
    hiddennav.style.opacity = "0";
    setTimeout(() => hiddennav.style.opacity = "1", 150);
  }
}

function showallnews() {
  const [newsbox4, newsbox5, viewallbutton] = [
  document.querySelector('.news_boxes:nth-child(4)'),
  document.querySelector('.news_boxes:nth-child(5)'),
  document.querySelector('#viewallbutton')
  ];
  
  const isDisplayed = newsbox4.style.display == "block";
  const displayValue = isDisplayed ? "none" : "block";
  const buttonText = isDisplayed ? "View all" : "View less";
  
  [newsbox4.style.display, newsbox5.style.display, viewallbutton.textContent] = [displayValue, displayValue, buttonText];
}


function ExpandPolicy(currentTarget) {
  // Get the closest policybox to the clicked element
  const policybox = currentTarget.closest('.policyboxes');
  const plusminus = policybox.querySelector('.plusminus');
  const policytext = policybox.querySelector('.policytext');
  
  const isActive = policybox.classList.contains('active');
  
  // Toggle the 'active' class on the specific elements
  policybox.classList.toggle('active');
  plusminus.classList.toggle('active');
  
  // Adjust the height of the policy text for this specific box
  policytext.style.height = isActive ? '0' : `${policytext.scrollHeight}px`;
  policytext.style.marginTop = isActive ? '0' : '15px';
}



function changeDragScroll() {
  const dragscroll = document.getElementById('dragscroll');
  
  if (dragscroll.checked == true) {
    lenis.start();
  } else {
    lenis.stop();
  }
}

onMounted(() => {
  get_DB_Values();
  
  window.onscroll = () => {
    const scrolled = document.body.scrollTop > 2 || document.documentElement.scrollTop > 2;
    document.querySelector('.normalnav').style.height = scrolled ? "38px" : "48px";
    document.querySelector('.tasheader').style.fontSize = scrolled ? "13px" : "18px";
    document.querySelector('.hamburger').classList.toggle('hamburgerscrolled', scrolled);
  };
  
  const pinCards = document.querySelectorAll('.pincards_card');
  
  pinCards.forEach((pinCard, index) => {
    ScrollTrigger.create({
      trigger: pinCard,
      start: 'top center',
      end: 'bottom center',
      onEnter: () => {
        if (index === 0) {
          gsap.to(pinCard, { scale: 1, duration: 0.2 });
        } else {
          gsap.to(pinCard, { scale: 1, marginTop: 10, duration: 0.2 });
        }
      },
      onLeaveBack: () => {
        if (index === 0) {
          gsap.to(pinCard, { scale: 0.9, duration: 0.2 });
        } else {
          gsap.to(pinCard, { scale: 0.9, marginTop: -112, duration: 0.2 });
        }
      }
    });
  });
  
  getDate();
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.teamsswiper {
  @apply flex flex-col md:flex-row md:pb-4 overflow-visible items-center justify-start md:justify-center md:p-4 md:gap-10;
  
  &.swiper-slide-active {
    img {
      opacity: 1;
      transition-delay: .3s;
    }
    div {
      opacity: 1;
      transform: none;
      transition-delay: .3s;
    }
  }
  
  div:nth-child(1) {
    @apply w-full h-2/3 md:size-52 md:w-[150%] md:-ml-32 border-b-4 md:border-0 border-black md:rounded-xl  md:shadow-black/70 shadow-xl;
  }
  div:nth-child(2) {
    @apply flex flex-col px-6 pr-10 md:px-0 h-1/3 md:h-auto text-xs font-semibold gap-2 ml-2 md:py-10 md:pr-20 text-left md:ml-0 justify-center md:items-start md:justify-normal;
    
    p:nth-child(1) {
      @apply opacity-70;
    }
    h4 {
      @apply text-lg text-left font-bold;
    }
    p:nth-child(3) {
      @apply font-normal;
    }
  }
  img {
    opacity: 0;
    transition: opacity 0.3s;
  }
  div {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s, transform 0.3s;
  }
  
  #team1image { background-image: url('../assets/teams/1.jpg'); }
  #team2image { background-image: url('../assets/teams/2.jpg'); }
  #team3image { background-image: url('../assets/teams/3.jpg'); }
  #team4image { background-image: url('../assets/teams/4.jpg'); }
  #team5image { background-image: url('../assets/teams/5.jpg'); }
  #team6image { background-image: url('../assets/teams/6.jpg'); }
  #team7image { background-image: url('../assets/teams/7.jpg'); }
  
  #team1image, #team2image, #team3image, #team4image, #team5image, #team6image, #team7image {
    @apply bg-cover bg-center bg-no-repeat;
  }
}

.swiper-pagination {
  .swiper-pagination-bullet {
    transition: all 0.3s;
  }
  
  .swiper-pagination-bullet-active {
    height: 20px;
    border-radius: 100px;
  }
}

.readmore-button {
  background: linear-gradient(to right, #4ab1f1, #074081);
}

.pincards_card {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.5), #013900);
}

.footballimage {
  animation: ease-in-out 5s infinite footballAnimation;
}

#teamsrightsection {
  background-image: url('https://images.unsplash.com/photo-1624880357913-a8539238245b?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D.jpg');
  background-size: cover;
  background-position: center;
}

.committemembers:hover .scroll-container {
  animation-play-state: paused;
}

.commmembers:hover {
  scale: 1.05;
}

.plusminus {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  
  &.active {
    &:before {
      transform: translatey(-50%) rotate(-90deg);
      opacity: 0;
    }
    &:after {
      transform: translatey(-50%) rotate(0);
    }
  }
  
  &:before , &:after {
    content: "";
    display: block;
    background-color: white;
    position: absolute;		
    top: 50%; left: 0;
    transition: .35s;
    width: 100%;
    height: 3px;
  }
  
  &:before {		
    transform: translatey(-50%);
  }
  
  &:after {
    transform: translatey(-50%) rotate(90deg);
  }
  
}

.scroll-container {
  animation: marquee 15s linear infinite;
  
  div {
    @apply bg-cover bg-center bg-no-repeat;
  }
}

.scroll-container div:nth-child(1) { background-image: url('../assets/committee/1.jpg'); }
.scroll-container div:nth-child(2) { background-image: url('../assets/committee/2.jpg'); }
.scroll-container div:nth-child(3) { background-image: url('../assets/committee/3.jpg'); }
.scroll-container div:nth-child(4) { background-image: url('../assets/committee/4.jpg'); }
.scroll-container div:nth-child(5) { background-image: url('../assets/committee/5.jpg'); }
.scroll-container div:nth-child(6) { background-image: url('../assets/committee/6.jpg'); }
.scroll-container div:nth-child(7) { background-image: url('../assets/committee/7.jpg'); }


.SponsorsSwiper {
  .swiperslide {
    @apply shadow-lg shadow-black pb-10 px-6 gap-3 p-2 duration-300 rounded mx-auto;
    transition: margin-top 300ms ease, transform 300ms ease, opacity 300ms ease;
    
    div {
      @apply w-20 h-20 rounded mr-auto mt-4 bg-cover bg-center bg-no-repeat;
    }
    
    &.swiper-slide-active {
      @apply filter-none scale-100 z-10 shadow-black shadow-2xl;
    }
    
    #swiperslide1 { background-image: url("../assets/committee/1.jpg"); }
    #swiperslide2 { background-image: url("../assets/committee/2.jpg"); }
    #swiperslide3 { background-image: url("../assets/committee/3.jpg"); }
    #swiperslide4 { background-image: url("../assets/committee/4.jpg"); }
    #swiperslide5 { background-image: url("../assets/committee/5.jpg"); }
  }
}

.pastteams-container {
  .swiperslide:nth-child(1) { background-image: url('../assets/old-teams/team1.jpeg'); }
  .swiperslide:nth-child(2) { background-image: url('../assets/old-teams/team2.jpeg'); }
  .swiperslide:nth-child(4) { background-image: url('../assets/old-teams/team4.jpeg'); }
  .swiperslide:nth-child(5) { background-image: url('../assets/old-teams/team5.jpeg'); }
  .swiperslide:nth-child(6) { background-image: url('../assets/old-teams/team6.jpeg'); }
  .swiperslide:nth-child(7) { background-image: url('../assets/old-teams/team7.jpeg'); }
  .swiperslide:nth-child(8) { background-image: url('../assets/old-teams/team8.jpeg'); }
  .swiperslide:nth-child(9) { background-image: url('../assets/old-teams/team9.jpeg'); }
  .swiperslide:nth-child(10) { background-image: url('../assets/old-teams/team10.jpeg'); }
  
  .swiperslide {
    @apply size-40 scale-90 sm:size-52 rounded duration-300 opacity-50;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    &.swiper-slide-active {
      @apply scale-100 opacity-100;
    }
  }
}


:global(.swiper-pagination-bullet) {
  @apply bg-white;
}
.swiper-button-prev, .swiper-button-next {
  @apply text-white;
}
.swiper-button-prev::after, .swiper-button-next::after {
  @apply text-3xl;
}

.topheader {
  background-image: url('https://images.unsplash.com/photo-1517927033932-b3d18e61fb3a?q=80&w=1338&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  
  @apply bg-cover bg-center bg-no-repeat;
}

.hamburger {
  @apply mt-[5px];
  
  div {
    @apply relative w-6 h-[2px] rounded-[3px] bg-white mt-[5px] duration-300;
  }
}

.hamburgerscrolled {
  @apply scale-[0.8];
  top: 2px !important;
  right: 5px !important;
}

#toggle:checked + .hamburger .top-bun {
  @apply mt-[14px] -rotate-45;
}
#toggle:checked + .hamburger .bottom-bun {
  @apply rotate-45 opacity-0;
}
#toggle:checked + .hamburger .meat {
  @apply -mt-[2px] rotate-45;
}
#toggle:checked + .hamburger + .nav {
  @apply scale-100 top-0;
}

#next-match {
  background-image: url("../assets/pitch.jpg");
  @apply bg-cover bg-center bg-no-repeat;
}

.team-match {
  @apply -skew-x-[10deg];
}

.news_boxes {
  @apply cursor-pointer w-[160px] sm:w-[250px] duration-200 hover:scale-[0.98];
  font-family: "Signika Negative", sans-serif;
  
  .newsimages {
    @apply bg-cover bg-center bg-no-repeat;
  }
  
  .newimage1 { background-image: url("https://cdn.pixabay.com/photo/2015/01/26/22/40/child-613199_1280.jpg"); }
  .newimage2 { background-image: url("https://images.unsplash.com/photo-1517466787929-bc90951d0974?q=80&w=1286&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D.jpg"); }
  .newimage3 { background-image: url("https://images.unsplash.com/photo-1624880357913-a8539238245b?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D.jpg"); }
  .newimage4 { background-image: url("https://cdn.pixabay.com/photo/2022/08/17/15/46/soccer-7392844_1280.jpg"); }
  .newimage5 { background-image: url("https://images.unsplash.com/photo-1624280157150-4d1ed8632989?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D.jpg"); }
}

.news_boxes:nth-child(4) {
  @apply hidden;
}
.news_boxes:nth-child(5) {
  @apply hidden;
}

.policyboxes {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: black;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.455);
    border-radius: 50px;
  }
}

.contactform {
  background-image: url('https://cdn.pixabay.com/photo/2017/06/18/14/16/football-2415894_1280.jpg');
  @apply bg-cover bg-center bg-no-repeat;
}

@keyframes footballAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 100vw));
  }
}
</style>

<!-- Backup football -->
<!-- <div class="hidden">
<div id="ring1" class="mt-24 mr-2 sm:mr-auto sm:mt-auto opacity-0 outline-double outline-[7px] outline-blue-400 duration-500 shadow-2xl shadow-blue-400/80 border-solid rounded-full flex items-center justify-center md:size-72 size-36 2xl:size-[350px]">
<div id="ring2" class="opacity-0 duration-500 outline-double outline-[7px] outline-blue-400/50 border-solid rounded-full flex items-center justify-center md:size-64 size-32 2xl:size-80">
<div id="ring3" class="opacity-0 duration-500 outline-double outline-[7px] outline-blue-400/20 border-solid rounded-full flex items-center justify-center md:size-56 size-28 2xl:size-72">
<img class="footballimage" src="../assets/football.png" alt="">
</div>
</div>
</div>
</div> -->